<template>
  <div id="open-room">
    <TopMenu
      :title="$t('openRoom.title')"
      :customBack="() => {$router.push('/hotel/' + this.id)}"
    />
    <div class="content">
      <div class="loading">
        <div :class="loaded ? 'lds-facebook animate__animated animate__fadeOutLeft' : 'lds-facebook'">
          <div></div><div></div><div></div>
        </div>
      </div>
      
      <div v-if="loaded" class="status-icon">
        <md-icon class="md-size-3x animate__animated animate__fadeInRight" :class="{fail: !success}">
          {{success  ? 'check_circle_outline' : 'error_outline'}}
        </md-icon>
      </div>

      <div v-if="loaded" class="status-label animate__animated animate__fadeInUp">{{message}}</div>
    </div>
  </div>
</template>

<script>
import TopMenu from '@/components/TopMenu'

export default {
  props: ['id', 'room'],
  components: { TopMenu },
  data() {
    return {
      message: '',
      loaded: false,
      success: false,
    }
  },
  mounted() {
    this.unlock()
  },
  methods: {
    unlock() {
      this.loaded = false
      this.success = false
      this.$api.get(`pms/user-stays/${this.id}/unlock/${this.room}`)
      .then(res => {
        if(res.data.data != true) {
          this.message = res.data?.message
          return
        }

        this.message = this.$t('openRoom.success')
        this.success = true
      })
      .catch(err => {
        if(this.$store.isLoginError(err)) {
          this.message = this.$t('sidebar.logIntoAccount')
          this.$events.once('authorized', () => this.unlock())
          return
        }
        this.message = err.response?.data?.message || err.message
      })
      .finally(() => {
        this.loaded = true
      })
    }
  },
  computed: {
  }
}
</script>

<style lang="scss">
@import '@/scss/lds/facebook.scss';

#open-room >.content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  >.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

      >.lds-facebook * {
        background: var(--md-theme-default-primary);
      }
    }

  >.status-icon {
    >.md-icon {
      color: rgb(124, 192, 87);

      &.fail {
        color:rgb(192, 90, 87);
      }
    }
  }

  >.status-label {
    font-size: 18px;
    text-align: center;
  }
}
</style>